<template>
    <div>
        <Header></Header>
        <div class="gameDetail">
            <div class="gameDetail_banner">
                <el-carousel :interval="4000" type="card" trigger="click" arrow="never">
                    <el-carousel-item v-for="(item,index) in date" :key="index">
                        <img :src="item.content" alt="">
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="gameDetail_info">
                <div v-if="isCollection" class="gameDetail_collection" @click="collectGame()"></div>
                <div v-else class="gameDetail_noCollection" @click="collectGame()"></div>
                <div class="infoTop">
                    <div class="infoTop_left">
                        <img :src="gameLogo" :alt="gameName">
                        <div>
                            <h3>{{gameName}}</h3>
                            <p>{{short_game_desc}}</p>
                            <div>
                                <span v-for="(item,index) in gameTags" :key="index">{{item}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="infoTop_right">
                        <div class="infoTop_grade">
                            <p>{{gameRatings}}</p>
                            <span>{{ratingsCount}}人评分</span>
                        </div>
                        <div class="infoTop_detail">
                            <p v-for="item in ratingsDetail" :key="item.rating">{{item.rating}}<i></i><span><span v-if="!ratingsCount" style="width: 0px"></span><span v-else :style="{width: (item.count/ratingsCount)*240 + 'px'}"></span></span></p>
                        </div>
                    </div>
                </div>
                <div class="infoMiddle"></div>
                <div class="infoBottom">
                    <div class="infoBottom_left">
                        <p @click.stop="seeMore" :class="{active: show == true}">{{description}}</p>
                        <div>
                            <p><span></span>{{operationMethod}}</p>
                            <p><span></span>{{gamePublisher}}</p>
                        </div>
                    </div>
                    <div class="infoBottom_right" @click="startGame(gameId)"></div>
                </div>
            </div>
            <div class="gameDetail_comment">
                <h2>玩家评价</h2>
                <div v-if="$store.state.login.auth" class="comment_input">
                    <el-input
                            id="commentInput"
                            type="textarea"
                            :rows="3"
                            placeholder="请输入内容"
                            maxlength="300"
                            show-word-limit
                            v-model="textarea">
                    </el-input>
                    <div class="comment_btn" @click="submitComment()"><p>发表评论</p></div>
                </div>
                <div v-else class="comment_noInput">
                    <div class="comment_noText">
                        <p><span @click="login">登录</span>后才能发表评论哦</p>
                    </div>
                    <div class="comment_noBtn"><p>发表评论</p></div>
                </div>
                <div class="comment_setting">
                    <div class="setting_em" @click="()=>{emojiStatus=!emojiStatus}">表情</div>
                    <div class="setting_start">
                        <span>轻点发布我的评价</span>
                        <el-rate v-model="valueStart"></el-rate>
                    </div>
                    <div class="setting_emoji" v-show="emojiStatus">
                        <div v-for="(item) in emojis" :key="item.id" @click="inputEmoji(item)">{{item}}</div>
                    </div>
                </div>
                <template v-if="commentData.length > 0">
                    <div class="comment_info">
                        <div v-for="item in commentData" :key="item.comment_id">
                            <CommentList :dispose="dispose" :item="item" @callBackComment="callBackComment" @reviewDialogEle="reviewDialogEle" :ref="'commentListParent'+ item.comment_id" />
                            <div class="chidList">
                                <div v-if="item.reply.length > 0">
                                    <CommentList
                                        v-for="itemC in item.reply"
                                        :key="itemC.comment_id"
                                        :dispose="disposeChild"
                                        :parentId="item.comment_id"
                                        :item="itemC"
                                        @callBackComment="callBackComment"
                                        @reviewDialogEle="reviewDialogEle"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="comment_page">
                        <el-pagination class="bigPage"
                                       :pager-count=5
                                       @current-change="handleCurrentChange"
                                       :current-page.sync="currentPage"
                                       :page-size="pageSize"
                                       layout="total, prev, pager, next, jumper"
                                       :total="pageSum"
                                       :prev-text="changePage.pre"
                                       :next-text="changePage.nex">
                        </el-pagination>
                        <el-pagination class="smaPage"
                                       :pager-count=5
                                       @current-change="handleCurrentChange"
                                       :current-page.sync="currentPage"
                                       :page-size="pageSize"
                                       layout="prev, pager, next"
                                       :total="pageSum">
                        </el-pagination>
                    </div>
                </template>
                <template v-else>
                    <div class="comment_noInfo">
                        <img src="@/assets/images/officialWebsite/news/news-nocontent.png" alt="">
                    </div>
                </template>
            </div>
        </div>
        <Footer></Footer>

        <el-dialog
            title="查看回复"
            id="answerDialog"
            :visible.sync="reviewAnswerStatus"
            width="800px">
            <div v-if="reviewCommentDialog.length > 0">
                <CommentList
                v-for="item in reviewCommentDialog"
                :key="item.id"
                :parentId="item.comment_id"
                :dispose="disposeDialog"
                :item="item"
                @callBackComment="callBackComment"
                @reviewDialogEle="reviewDialogEle"/>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import launchGameUtil from "@/components/cloudGameLauncher/launchGameUtil";
    import CommentList from "@/components/officialWebsite/CommentList.vue";

    export default {
        name: "GameDetail",
        data () {
            return {
                isCollection: false,
                collectionTimer: false,
                date: [], // 图片信息
                emojis: [
                    '😀','😃','😄','😁','😆','😅','🤣','😂','🙂','🙃','😉','😊','😇','🥰','😍','🤩','😘','😗','😚','😙',
                    '😋','😛','😜','🤪','😝','🤑','🤗','🤭','🤫','🤔','🤐','🤨','😐','😑','😶','😏','😒','🙄','😬','🤥',
                    '😌','😔','😪','🤤','😴','😷','🤒','🤕','🥵','🥶','🥴','😵','🥳','😎','🤓','🧐','😕','😟','🙁','😮',
                    '😯','😲','😳','🥺','😦','😧','😨','😰','😥','😢','😭','😱','😖','😣', '😞','😓','😩','😫','🥱','😤',
                    '😡','😠','😈','👿','❤️','❤️‍🔥',
                ],
                textarea: '',       // 评论信息
                valueStart: null,   // 评星
                currentPage: 1,     // 当前页
                pageSize: 0,        // 每页显示个数
                pageSum: 0,         // 新闻总数
                changePage:{
                    pre:"上一页",
                    nex:"下一页"
                },
                emojiStatus: false,     // 表情包
                gameId: '',             // 游戏id
                gameName: '',           // 游戏名称
                gameLogo: '',           // 游戏logo
                description: '',        // 详细介绍
                short_game_desc: '',    // 简介
                gameTags: [],           // 游戏标签
                gameRatings: '',        // 游戏评分
                ratingsCount: '',       // 评分次数
                ratingsDetail: [],      // 评分详情
                gamePublisher: '',      // 游戏开发商
                gameOperation: [],      // 操作方式
                commentData: [],        // 评论信息
                reviewCommentDialog: [],  //查看信息所有数据
                reviewCommentDataAll: [], // 查看信息所有数据
                timer: null,
                show: true,
                dispose:{
                    switch:false,
                    level: 1,          //层级
                    delClass:'delMsgBox'
                },
                disposeChild:{
                    review: false,      //
                    level: 2,          //层级
                    delClass: 'delMsgBox'
                },
                disposeDialog:{
                    switch:false,
                    review: false,
                    level: 2,          //层级
                    delClass: 'delMsgBox'
                },
                pageVal:1,              //当前一级列表在第几页
                dialogCommentId: 0,      //弹框数据的存储
                reviewAnswerStatus:false,
                currentLimit: false, // 评论限流
            }
        },
        created() {
            // 预留，表情通过父级给
            this.dispose.emojis = this.emojis;
            this.disposeChild.emojis = this.emojis;
            this.disposeDialog.emojis = this.emojis;

            this.gameId = this.$route.params.game_id
            this.getGameInfo()
            this.getGameComment({game_id:this.gameId,page:1})
        },
        watch: {
            currentLimit (state) {
                let t = null
                state ?  t = setTimeout(()=>{this.currentLimit = false},10000) : clearTimeout(t)
            }
        },
        computed: {
            operationMethod() {
                if (this.gameOperation.length<=1) {
                    return this.gameOperation[0]
                } else {
                    return this.gameOperation.reduce((previousValue, currentValue)=>{
                        return previousValue + ', ' + currentValue
                    })
                }
            }
        },
        methods: {
            collectGame () {
                if (this.$store.state.login.auth) {
                    this.isCollection = !this.isCollection
                    if(this.collectionTimer) clearTimeout(this.collectionTimer)
                    this.collectionTimer = setTimeout(()=>{
                        if (!this.isCollection) {
                            this.$API.collectionNoStatue({game_id:this.gameId})
                        } else {
                            this.$API.collectionStatue({game_id:this.gameId})
                        }
                    },1500)
                }
            },
            seeMore () {
                this.show = !this.show
            },
            login() {
                this.$router.replace({
                    name:"Login",
                    query: {redirect: this.$router.currentRoute.fullPath}
                })
            },
            startGame(gameId) {
               launchGameUtil.startGameReview(gameId, this);
            },
            commentNice(item) {
                if (this.$store.state.login.auth) {
                    item.self_favored ? item.favorite_count-=1 : item.favorite_count+=1
                    item.self_favored == 0 ? item.self_favored = 1 : item.self_favored = 0
                    let a = item.self_favored
                    let b = item.comment_id
                    if(this.timer) clearTimeout(this.timer)
                    this.timer = setTimeout(()=>{
                        this.$API.addGameNice({favor: a, comment_id: b})
                    },1500)
                }
            },
            handleCurrentChange(val) {
                this.pageVal = val;
                this.getGameComment({game_id:this.gameId,page:val})
            },
            async inputEmoji(value) {
                if (document.querySelector('#commentInput')) {
                    const myField = document.querySelector('#commentInput');
                    if(myField.selectionStart || myField.selectionStart === 0) {
                        let startPos = myField.selectionStart;
                        let endPos = myField.selectionEnd;
                        this.textarea = myField.value.substring(0, startPos) + value
                            + myField.value.substring(endPos, myField.value.length);
                        await this.$nextTick()
                        myField.focus();
                        myField.setSelectionRange(endPos + value.length, endPos + value.length);
                    } else {
                        this.textarea = value;
                    }
                }
                this.emojiStatus = false
            },
            submitComment() {
                let obj = {
                    game_id: this.gameId,
                    content: this.textarea,
                    rating: this.valueStart
                }

                if (!this.textarea) {
                    this.$store.commit('MESSAGE_TEXT', '评论不能为空')
                } else {
                    if (this.currentLimit) {
                        this.$store.commit('MESSAGE_TEXT', '请勿频繁评论')
                    } else {
                        this.$API.addGameComment(obj).then((res)=>{
                            if (res.data.status == 1) {
                                this.$store.commit('MESSAGE_TYPE', true)
                                this.$store.commit('MESSAGE_TEXT', '评论成功')
                                this.textarea = ''
                                this.valueStart = null
                                this.currentLimit = true
                                this.getGameComment({game_id:this.gameId,page:1})
                            }
                        })
                    }
                }
            },
            getGameInfo() {
                this.$API.getGameDetail({game_id: this.gameId}).then(res=>{
                    if (res.data.status == 1) {
                        let { data } = res.data
                        this.date = data.nav
                        this.gameName = data.name
                        this.gameLogo = data.logo
                        this.description = data.description
                        this.short_game_desc = data.short_game_desc
                        this.gameTags =  data.tags
                        this.gameRatings = data.ratings.avg_ratings
                        this.ratingsCount = data.ratings.ratings_total_count
                        this.ratingsDetail = data.ratings.details
                        this.gamePublisher = data.publisher
                        this.gameOperation = data.operation_methods
                        this.isCollection = data.collect_status ? true : false
                    }
                })
            },
            getGameComment(obj) {
                this.$API.getGameComment(obj).then((res)=>{
                    if (res.data.status == 1) {
                        let { data } = res.data
                        this.commentData = data.comments
                        this.pageSize = data.meta.limit
                        this.pageSum = data.meta.count
                    }
                })
            },
            callBackComment(){
                this.getGameComment({game_id:this.gameId,page:this.pageVal})
            },
            reviewDialogEle({comment_id,bool,replyCount,self_id}){
                /**
                 * 查看更多弹框请求
                 * bool:是否是二级回复，是清空该id数据
                 * data：该条"查看回复"的数据
                 * replyCount: 回头写XXX
                 */

                if(bool){
                    this.reviewCommentDataAll[comment_id] = [];
                } else {
                    this.disposeDialog.parent_id = comment_id;
                    this.disposeChild.parent_id = comment_id;
                }

                let data =  this.reviewCommentDataAll[comment_id];

                // 判断是否已有请求过后的数据
                if( data == undefined || data.length <= 0){
                    this.$API.getReviewComment({comment_id}).then((res)=>{
                        if(res.data.status == 1){
                            if(res.data.data.reply.length > 0){

                                this.reviewCommentDialog = res.data.data.reply;
                                // 将新请查看回复存储
                                this.reviewCommentDataAll[comment_id] = res.data.data.reply;
                                //二级不关闭弹框
                                if(!bool){
                                    this.reviewAnswerStatus = !this.reviewAnswerStatus;
                                }
                                if(replyCount != res.data.data.reply.length && this.$refs['commentListParent' + comment_id][0]){
                                    this.$refs['commentListParent'+comment_id][0].setCommentCount({comment_id,newReplyCount:res.data.data.reply.length})
                                }
                            }else{
                                this.reviewAnswerStatus = false;
                            }
                        }
                    });
                }else{
                    // 现有的回复赋值
                    this.reviewCommentDialog = data;
                    this.reviewAnswerStatus = !this.reviewAnswerStatus;

                }

            },

        },
        components:{
            CommentList
        }
    }
</script>

<style scoped lang="scss">

@media screen and (min-width: 1200px) {
    .gameDetail {
        width: 1100px;
        margin: 0 auto;
        .gameDetail_banner {
            width: 1600px;
            transform: translateX(-250px);
            /deep/ .el-carousel__container {
                height: 450px;
            }
        }
        .gameDetail_info {
            .infoTop {
                justify-content: space-between;
            }
            .infoBottom {
                justify-content: space-between;
                .infoBottom_left {
                    width: 600px;
                    div {
                        justify-content: space-between;
                    }
                }
                .infoBottom_right {
                    width: 340px;
                }
            }
        }
        .gameDetail_comment {
            width: 1100px;
            margin: 0 auto;
            .comment_input, .comment_noInput {
                padding: 16px 56px 0 75px;
            }
            .comment_setting {
                .setting_em {
                }
                .setting_start {
                    margin: 18px 160px 0 0;
                }
                .setting_emoji {
                    left: 75px;
                }
            }
            .comment_info {
                padding: 0 56px 0 75px;
            }

        }
    }
}
@media screen and (max-width: 1200px) and (min-width: 1000px) {
    .gameDetail {
        width: 100%;
        .gameDetail_banner {
            /deep/ .el-carousel__container {
                height: 300px;
            }
        }
        .gameDetail_info {
            .infoTop {
                justify-content: space-between;
            }
            .infoBottom {
                justify-content: space-between;
                .infoBottom_left {
                    width: 500px;
                    div {
                        justify-content: space-between;
                    }
                }
                .infoBottom_right {
                    width: 340px;
                }
            }
        }
        .gameDetail_comment {
            .comment_input, .comment_noInput {
                padding: 16px 56px 0 75px;
            }
            .comment_setting {
                .setting_em {
                }
                .setting_start {
                    margin: 18px 160px 0 0;
                }
                .setting_emoji {
                    left: 75px;
                }
            }
            .comment_info {
                padding: 0 56px 0 75px;
            }
        }
    }
}
@media screen and (max-width: 1000px) and (min-width: 700px) {
    .gameDetail {
        width: 100%;
        .gameDetail_banner {
            /deep/ .el-carousel__container {
                height: 300px;
            }
        }
        .gameDetail_info {
            .infoTop {
                justify-content: space-between;
            }
            .infoBottom {
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                .infoBottom_left {
                    div {
                        justify-content: space-between;
                    }
                }
                .infoBottom_right {
                    width: 340px;
                }
            }
        }
        .gameDetail_comment {
            .comment_input, .comment_noInput {
                padding: 16px 56px 0 50px;
            }
            .comment_setting {
                .setting_em {
                    margin: 8px 0 0 50px!important;
                }
                .setting_start {
                    margin: 18px 160px 0 0;
                }
                .setting_emoji {
                    left: 50px;
                }
            }
            .comment_info {
                padding: 0 56px 0 50px;
            }
        }
    }
    /deep/ #answerDialog{
        .el-dialog{
            width: 100% !important;
        }
    }
}
@media screen and (max-width: 700px) and (min-width: 500px){
    .gameDetail {
        .gameDetail_banner {
            width: 100%;
            /deep/ .el-carousel__container {
                height: 200px;
            }
        }
        .gameDetail_info {
            /*padding: 40px 20px!important;*/
            .infoTop {
                justify-content: space-between;
                flex-direction: column;
                align-items: flex-start;
                .infoTop_right {
                    .infoTop_detail {
                        margin-top: 10px;
                    }
                }
            }
            .infoBottom {
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                .infoBottom_left {
                    div {
                        justify-content: space-between;
                    }
                }
                .infoBottom_right {
                    width: 340px;
                    margin: 16px 0 0 0!important;
                }
            }
        }
        .gameDetail_comment {
            .comment_input, .comment_noInput {
                padding: 16px 56px 0 50px;
            }
            .comment_setting {
                .setting_em {
                    margin: 8px 0 0 50px!important;
                }
                .setting_start {
                    margin: 18px 100px 0 0;
                }
                .setting_emoji {
                    left: 50px;
                }
            }
            .comment_info {
                padding: 0 56px 0 50px;
            }
        }
    }

    /deep/ #answerDialog{
        .el-dialog{
            width: 100% !important;
        }
    }
}
@media screen and (max-width: 500px) {
    .gameDetail {
        width: 100%;
        .gameDetail_banner {
            margin: 14px auto 15px!important;
            width: 600px;
            transform: translateX(-100px);
            /deep/ .el-carousel__container {
                height: 200px;
            }
        }
        .gameDetail_info {
            padding: 40px 20px!important;
            .infoTop {
                justify-content: space-between;
                flex-direction: column;
                align-items: flex-start;
                .infoTop_right {
                    .infoTop_detail {
                        margin-top: 10px;
                    }
                }
            }
            .infoBottom {
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                .infoBottom_left {
                    div {
                        justify-content: space-between;
                    }
                }
                .infoBottom_right {
                    width: 340px;
                    margin: 16px 0 0 0!important;
                }
            }
        }
        .gameDetail_comment {
            h2 {
                margin: 56px 0 0 20px!important;
            }
            .comment_input, .comment_noInput {
                padding: 16px 20px 0 20px;
            }
            .comment_setting {
                .setting_em {
                    margin: 8px 0 0 20px!important;
                }
                .setting_start {
                    margin: 15px 100px 0 20px!important;
                    span {display: none}
                }
                .setting_emoji {
                    left: 20px;
                }
            }
            .comment_info {
                padding: 0 20px 0 20px;
                .commentInfo {
                    img {
                        width: 56px;
                        height: 56px;
                        border-radius: 28px;
                    }
                }
            }
            .comment_page {
                .bigPage {
                    display: none!important;
                }
                .smaPage {
                    display: block!important;
                }
            }
        }
    }
    /deep/ #answerDialog{
        .el-dialog{
            width: 100% !important;
        }
    }
}


div,p,h3,span,img,h2,p {margin: 0;padding: 0}
.gameDetail {
    overflow: hidden;
    .gameDetail_banner {
        margin: 24px auto 39px;
        .el-carousel__item {
            border-radius: 10px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .gameDetail_info {
        min-height: 340px;
        background: #FFFAF4;
        border-radius: 8px;
        padding: 40px 56px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        .gameDetail_collection, .gameDetail_noCollection {
            position: absolute;
            top: 0;right: 0;
            width: 40px;
            height: 40px;
            background: #FF7878 url("../../../assets/images/officialWebsite/game/game-collection.png") no-repeat center;
            background-size: 26px 26px;
            border-radius: 0px 8px 0px 8px;
            cursor: pointer;
        }
        .gameDetail_noCollection {
            background: #E6E6E6 url("../../../assets/images/officialWebsite/game/game-collection.png") no-repeat center;
            background-size: 26px 26px;
        }
        .infoTop {
            display: flex;
            .infoTop_left {
                display: flex;
                justify-content: flex-start;
                img {
                    width: 96px;
                    height: 96px;
                    border-radius: 8px;
                    margin-right: 16px;
                }
                div {
                    h3 {
                        color: #D5A561;
                        font-size: 22px;
                        line-height: 30px;
                        font-weight: 600;
                    }
                    p {
                        color: #222222;
                        font-size: 14px;
                        margin: 8px 0 8px 0;
                        font-weight: 400;
                        min-height: 23px;
                    }
                    div {
                        span {
                            float: left;
                            height: 24px;
                            background: #F2EEEA;
                            border-radius: 4px;
                            padding: 0 10px;
                            line-height: 24px;
                            font-size: 14px;
                            font-weight: 400;
                            color: #606062;
                            margin: 4px 8px 0 0;
                        }
                    }
                }
            }
            .infoTop_right {
                display: flex;
                justify-content: flex-end;
                .infoTop_grade {
                    margin-right: 14px;
                    p {
                        width: 67px;
                        height: 40px;
                        background: url("../../../assets/images/officialWebsite/game/game-grade.png") no-repeat left center;
                        background-size: 22px 26px;
                        padding-left: 26px;
                        font-size: 34px;
                        font-weight: bold;
                        color: #2F333D;
                        line-height: 40px;
                    }
                    span {
                        height: 18px;
                        font-size: 12px;
                        font-weight: 500;
                        color: #606062;
                        line-height: 18px;
                        margin: 10px 6px 0 0;
                    }
                }
                .infoTop_detail {
                    margin-left: 14px;
                    p {
                        height: 18px;
                        font-size: 12px;
                        font-weight: 500;
                        color: #606062;
                        line-height: 18px;
                        display: flex;
                        align-items: center;
                        i {
                            width: 10px;
                            height: 10px;
                            background: url("../../../assets/images/officialWebsite/game/game-stars.png") no-repeat;
                            background-size: cover;
                            margin: 0 4px 0 2px;
                        }
                        span {
                            width: 240px;
                            height: 6px;
                            background: #E6E6E6;
                            border-radius: 3px;
                            opacity: 0.8;
                            position: relative;
                            span {
                                position: absolute;
                                height: 6px;
                                background: #D79B23;
                                border-radius: 3px 0px 0px 3px;
                            }
                        }
                    }
                }
            }
        }
        .infoMiddle {
            height: 1px;
            background: #FAD8A1;
            margin: 24px 0;
        }
        .infoBottom {
            display: flex;
            .infoBottom_left {
                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #000000;
                    line-height: 20px;
                    margin-bottom: 21px;
                }
                .active {
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
                div {
                    display: flex;
                    p {
                        height: 18px;
                        font-size: 14px;
                        font-weight: 500;
                        color: #000000;
                        line-height: 18px;
                        span {
                            padding-left: 26px;
                            height: 18px;
                            font-size: 14px;
                            font-weight: 400;
                            color: #606062;
                            line-height: 18px;
                        }
                    }
                    p:nth-child(1) {
                        background: url("../../../assets/images/officialWebsite/game/game-operate.png") no-repeat left center;
                        background-size: 18px 18px;
                    }
                    p:nth-child(2) {
                        background: url("../../../assets/images/officialWebsite/game/game-developer.png") no-repeat left center;
                        background-size: 18px 18px;
                    }
                }
            }
            .infoBottom_right {
                height: 56px;
                margin: 16px 0 0 34px;
                cursor: pointer;
                background: url("../../../assets/images/officialWebsite/game/game-startbtn.png") no-repeat;
                background-size: cover;
            }
            .infoBottom_right:hover {
                background: url("../../../assets/images/officialWebsite/game/game-startbtnhover.png") no-repeat;
                background-size: cover
            }
        }
    }
    .gameDetail_comment {
        background: #FFFAF4;
        border-radius: 8px;
        width: 100%;
        margin: 32px auto 80px;
        overflow-y: hidden;
        h2 {
            height: 33px;
            font-size: 24px;
            font-weight: 400;
            color: #000000;
            line-height: 33px;
            background: url("../../../assets/images/officialWebsite/game/game-comment.png") no-repeat left center;
            background-size: 22px 26px;
            padding-left: 30px;
            margin: 56px 0 0 51px;
        }
        .comment_input {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            /deep/ .el-textarea {
                .el-textarea__inner {
                    height: 72px;
                    resize: none;
                    border: 1px solid #FAD8A1;
                }
            }
            .comment_btn {
                width: 72px;
                height: 72px;
                flex-shrink: 0;
                margin-left: 32px;
                border-radius: 8px;
                display: flex;
                cursor: pointer;
                align-items: center;
                justify-content: center;
                background: linear-gradient(154deg, #FAD8A1 0%, #E3AF6D 100%);
                p {
                    width: 36px;
                    height: 50px;
                    font-size: 18px;
                    color: #FFFFFF;
                    font-weight: 400;
                    line-height: 25px;
                }
            }
            .comment_btn:hover {
                background: linear-gradient(179deg, #FFDC96 0%, #F1AE50 100%);
            }
        }
        .comment_noInput {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .comment_noText {
                width: 90%;
                height: 72px;
                background: #FFFFFF;
                border-radius: 8px;
                border: 1px solid #FAD8A1;
                p {
                    color: #ADADB0;
                    font-size: 14px;
                    margin: 8px 0 0 16px;
                    span {
                        color: #C8964E;
                        font-size: 16px;
                        cursor: pointer;
                        margin-right: 5px;
                    }
                }
            }
            .comment_noBtn {
                flex-shrink: 0;
                cursor: pointer;
                margin-left: 32px;
                width: 72px;
                height: 72px;
                background: linear-gradient(154deg, #E6E6E6 0%, #ADADB0 100%);
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                p {
                    width: 36px;
                    height: 50px;
                    font-size: 18px;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 25px;
                }
            }
        }
        .comment_setting {
            display: flex;
            justify-content: space-between;
            position: relative;
            .setting_em {
                cursor: pointer;
                flex-shrink: 0;
                width: 72px;
                height: 32px;
                background: #FFFFFF;
                border-radius: 8px;
                border: 1px solid #FAD8A1;
                font-size: 14px;
                font-weight: 400;
                color: #D5A561;
                line-height: 30px;
                background-image: url("../../../assets/images/officialWebsite/game/game-em.png");
                background-repeat: no-repeat;
                background-position: 10px center;
                background-size: 18px 18px;
                padding-left: 34px;
                margin: 8px 0 0 75px;
            }
            .setting_start {
                display: flex;
                span {
                    font-size: 14px;
                    font-weight: 400;
                    color: #ADADB0;
                    margin-right: 8px;
                }
                /deep/ .el-icon-star-on {
                    color: #D5A561!important;
                }
            }
            .setting_emoji {
                position: absolute;
                top:40px;
                padding: 10px;
                width: 85%;
                background: #f8eee1;
                z-index: 1;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                flex: 1;
                div {
                    width: 24px;
                    height: 24px;
                    margin: 5px 0 5px 5px;
                    cursor: pointer;
                }
            }
        }
        .comment_info {
            margin-top: 40px;
            .chidList{
                padding-left: 72px;
                // overflow-x: hidden;
            }
            .commentInfo {
                margin-top: 26px;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                img {
                    width: 56px;
                    height: 56px;
                    border-radius: 28px;
                    margin-right: 16px;
                }
                .commentInfo_item {
                    width: 100%;
                    min-height: 150px;
                    position: relative;
                    border-bottom: 1px solid #E6E6E6;
                    h3 {
                        height: 24px;
                        font-size: 18px;
                        font-weight: 500;
                        color: #222222;
                        line-height: 24px;
                    }
                    p {
                        height: 20px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #606062;
                        line-height: 20px;
                        margin: 8px 0 11px 0;
                    }
                    .commentInfo_text {
                        width: 95%;
                        font-size: 16px;
                        font-weight: 500;
                        color: #2F333D;
                        line-height: 24px;
                        margin-bottom: 20px;
                        word-break:break-all;
                        word-wrap:break-word
                    }
                    .commentInfo_btns{
                        // 点赞，查看回复，回复按钮
                        >span {
                            font-size: 14px;
                            margin-right: 8px;
                            cursor: pointer;
                            color: #606062;
                            i {
                                width: 16px;
                                height: 16px;
                                margin-right: 5px;
                                display: inline-block;
                                vertical-align:text-bottom;
                                background-size: cover !important;
                            }
                            .noNice {
                                background: url("../../../assets/images/officialWebsite/game/game-nice.png") no-repeat left top;
                                margin-bottom: 1px;
                            }
                            .Nice {
                                background: url("../../../assets/images/officialWebsite/game/game-nices.png") no-repeat left top;
                                margin-bottom: 1px;
                            }
                            .i-answer.active {
                                background: url("../../../assets/images/officialWebsite/i-answer-active.png") no-repeat left top;
                            }
                            .i-answer {
                                background: url("../../../assets/images/officialWebsite/i-answer.png") no-repeat left top;
                            }
                            // 查看回复
                            .i-review-answer {
                                background: url("../../../assets/images/officialWebsite/i-review-answer.png") no-repeat left top;
                            }
                        }
                        // 查看回复
                        .reviveAnswer:hover{
                            .i-review-answer{
                                background: url("../../../assets/images/officialWebsite/i-review-answer-active.png") no-repeat left top;
                            }
                        }
                        // 回复评论输入框
                        .commentInfo_answer{
                            // 输入框与表情
                            .answerInput_box{
                                width: calc(100% - 72px);
                                float: left;
                                position: relative;
                                margin-bottom: 20px;

                                /deep/ .answerInput_textarea {
                                    width: 100%;
                                    .el-textarea__inner{
                                        resize: none;
                                        border: 1px solid #FAD8A1;
                                    }
                                }
                                .answerInput_emoji{
                                    width: 29px;
                                    height: 29px;
                                    border: none;
                                    outline: none;
                                    position: absolute;
                                    right: 2px;
                                    top: 2px;
                                    padding: 12px 0;
                                    background-size: 18px 18px;
                                    background-color: #FFFFFF;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-image: url("../../../assets/images/officialWebsite/game/game-em.png");
                                    }
                                    .emojiList{
                                        width: 300px;
                                        height: 175px;
                                        position: absolute;
                                        top: 35px;
                                        right: -125px;
                                        padding: 10px;
                                        background: #FFF;
                                        z-index: 1;
                                        border-radius: 8px;
                                        box-shadow: 0px 2px 8px 0px rgba(173, 173, 176, 0.2);
                                        ul{
                                            padding-left: 0px;
                                            margin-bottom: 0px;
                                            li{
                                                z-index: 1;
                                                display: flex;
                                                flex-wrap: wrap;
                                                justify-content: flex-start;
                                                flex: 1;
                                                div{
                                                    width: 24px;
                                                    height: 24px;
                                                    margin: 5px 0 5px 5px;
                                                    cursor: pointer;
                                                }
                                            }
                                        }
                                        .emojiNav_btn{
                                            position: absolute;
                                            bottom: 10px;
                                            width: 100%;
                                            height: 20px;
                                            text-align: center;
                                            >a{
                                                font-size: 12px;
                                                width: 14px;
                                                height: 14px;
                                                margin: 0px 5px;
                                                cursor: pointer;
                                            }
                                            >a:hover{
                                                color: #FAD8A1;
                                            }
                                        }
                                    }
                            }
                            .answerSubmit{
                                // 发布按钮
                                width: 70px;
                                height: 33px;
                                border-radius: 4px;
                                float: right;
                                border: none;
                                outline: none;
                                color: #FFFFFF;
                                background: linear-gradient(154deg, #FAD8A1 0%, #E3AF6D 100%);
                            }

                        }
                    }
                }
            }
        }
        .comment_page {
            width: 100%;
            margin: 40px auto 88px;
            display: flex;
            justify-content: center;
            /deep/ button, li {
                background: none;
            }
            /deep/ li.active {
                background: none;
                color: #D5A561!important;
            }
            /deep/ li {
                background: none;
            }
            /deep/ .el-input__inner {
                background: none;
            }
            /deep/ .el-input__inner:focus{border-color: #D5A561!important;}
            .smaPage {
                display: none;
            }
        }
        .comment_noInfo {
            width: 100%;
            height: 240px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-right: 40px;
            margin-top: 50px;
            margin-bottom: 50px;
            img {
                width: 270px;
                height: 200px;
            }
        }
    }
}

/deep/ #answerDialog{
    .el-dialog__header,
    .el-dialog__body{
        background: #FFFAF4;
    }
    .el-dialog__body{
        padding-top: 20px;
        >div>:nth-child(1){
            margin-top: 0;
        }
    }
}

</style>
